import React, {useEffect} from "react";
import Marquee from "react-fast-marquee";
import { useLocation } from 'react-router-dom';
import { Parallax } from "react-parallax";
import   image1 from "./images/keyboard.jpg";
import   hero_image from "./images/hero-image.jpg";
import ProjectsData from '../Projects/ProjectsData';
import TestimonialSlider from './TestimonialSlider';
import Clients from "./Clients";
import ParticlesBackground from './ParticlesBackground';
import { Link } from 'react-router-dom';

const techStackTerms = [
    "JavaScript",
    "React",
    "Node.js",
    "Python",
    "Angular",
    "Vue.js",
    "HTML5",
    "CSS3",
    "Sass",
    "MongoDB",
    "SQL",
    "Express.js",
    "Django",
    "Ruby",
    "C#",
  
  ];
  const techStackTerms2 = [
    "REST API",
    "GraphQL",
    "Webpack",
    "Redux",
    "Vue.js",
    "TypeScript",
    "SASS/SCSS",
    "Firebase",
    "TensorFlow",
    "PyTorch",
    "D3.js",
    "Bootstrap",
    "Material-UI",
    "Webpack",
    "Elasticsearch",
    
  ]
  const techStackTerms3 = [
    "ASP.NET",
    "Spring Boot",
    "PHP",
    "Laravel",
    "AWS",
    "Docker",
    "Kubernetes",
    "Git",
    "Jenkins",
    "CI/CD",
    "Kibana",
    "Redis",
    "PostgreSQL",
    "MySQL",
  
  ]
  


const Home = () =>{
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
          // Delay the scroll to ensure the DOM is fully loaded
          setTimeout(() => {
            const element = document.querySelector(hash);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100); // Adjust the delay as needed (100ms is usually sufficient)
        }
      }, [hash]);
    
    return(
        <>
    <ParticlesBackground />
        {/* <Intro /> */}
        <div className="hero-home-page">
            <div className="container-full-width">
                <div className="left-right-wrapper">
                    <div className="left-col">
                    <div class="small-title">FULL-SERVICE SOFTWARE COMPANY.</div>
                    <h2 className="title">WE DON'T JUST CODE, BUT LIVE FOR IT.</h2>
                    <p>We help businesses, start-ups and nonprofits scale and create brands, websites, tools, and apps that customers love.
                    </p>
                    <Link to={'/services/'} className="btn-outline-dark me-3">OUR SERVICES</Link>
                    <Link to={'/projects/'} className="btn-outline-dark">SEE OUR WORK</Link>

                    </div>
                    <div className="right-col">
                        <img src={hero_image} alt="heroimage"></img>
                    </div>

                </div>
                

            </div>
          

        </div>

            <section id="intro-wrapper" className="">
                {/* <ParticlesBackground2 /> */}
            </section>
            <div className="section section-recent-projects">
                <div className="container-full-width">
                    <div class="small-title">OUR WORK.</div>

                    <h2 className="section-title">RECENT PROJECTS.</h2>
                    <div className="projects-items-wrapper">
                        <ProjectsData max_items="6"/>

                    </div>

                </div>

            </div>
            <div className="section section-marquee-text border-top-bottom container-full-width">
                <div className="container-full-width">
                    <div class="small-title">SPECIALITIES.</div>
                    <h2 className="section-title">TECH STACKS.</h2>

                </div>
                <div className="border-top-bottom py-5 mx-5">
                    <Marquee direction="right" speed="50">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="left"  speed="60">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms2.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="right"  speed="50">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms3.join(" / ")}</div>
                        ))}
                    </Marquee>
                </div>
            </div>
            <Parallax bgImage={image1} strength={500}>
                <div className="parallax-home" style={{ height: 500 }}>
                    <div className="inner-text">
                        <div className="container-full-width">
                            <div class="small-title text-white">GET IN TOUCH.</div>
                        </div>
                        <div className="container-full-width display-4 main-text">
                        IF YOU CAN IMAGINE IT,

                                <br />WE CAN BUILD IT.
                        </div>
                    </div>
                
                </div>
            </Parallax>


            <div id="section-clients" className="section section-clients border-bottom border-top container-full-width">
                <div className="container-full-width">
                <div class="small-title">CLIENTS.</div>

                    <h2 className="section-title">our
evolutionary client network.</h2>
                    <Clients />

                </div>

            </div>
            <div id="client-testimonials" className="section section-testimonials container-full-width">
                <div className="container-full-width">
                    <div class="small-title">TESTIMONIALS.</div>

                    <h2 className="section-title">WHAT OUR CLIENTS SAY.</h2>
                    <TestimonialSlider />

                </div>

            </div>
           
        </>

    );
}

export default Home;